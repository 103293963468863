<template>
  <div>
    <!-- <Crumbs></Crumbs> -->
    <Edit :expensebacklogIshwow="true" :btnIshwow="false"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState({
      menus: state => state.user.menus,
    }),
  },
  watch: {},
  methods: {},
}
</script>

<style lang="scss" scope></style>
